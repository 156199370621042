import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/Katalogi-Dokumenty-Certyfikaty/About'
import Tiles from 'components/layout/Sections/Pages/Katalogi-Dokumenty-Certyfikaty/Tiles'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Katalogi',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Katalogi',
    },
  ],
}

const KatalogiPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.katalogi
  const PAGE_SEO = data?.wpPage?.seo

  const CATALOGUES = React.useMemo(
    () =>
      PAGE?.cataloguesList?.map((catalogue) => ({
        img: catalogue?.cataloguesListCatalogueImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: catalogue?.cataloguesListCatalogueImg?.altText,
        downloadLink: catalogue?.cataloguesListCatalogueLink,
        title: catalogue?.cataloguesListCatalogueTitle,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.cataloguesImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.cataloguesImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.cataloguesTitle}
        headingDesktop={PAGE?.cataloguesTitle}
        breadcrumbs={breadcrumbs}
      />
      <About description={PAGE?.cataloguesDescription} />
      <Tiles catalogues={CATALOGUES} />
    </Layout>
  )
}

export default KatalogiPage

export const query = graphql`
  query KatalogiPage {
    wpPage(slug: { regex: "/katalogi/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      katalogi {
        cataloguesTitle
        cataloguesDescription
        cataloguesImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        cataloguesList {
          cataloguesListCatalogueTitle
          cataloguesListCatalogueLink
          cataloguesListCatalogueImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 200, width: 300)
              }
            }
          }
        }
      }
    }
  }
`
